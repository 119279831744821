var exports = {};
exports.parse = parse;
exports.stringify = stringify;
var comma = ",";
var space = " ";
var empty = ""; // Parse comma-separated tokens to an array.

function parse(value) {
  var values = [];
  var input = String(value || empty);
  var index = input.indexOf(comma);
  var lastIndex = 0;
  var end = false;
  var val;

  while (!end) {
    if (index === -1) {
      index = input.length;
      end = true;
    }

    val = input.slice(lastIndex, index).trim();

    if (val || !end) {
      values.push(val);
    }

    lastIndex = index + 1;
    index = input.indexOf(comma, lastIndex);
  }

  return values;
} // Compile an array to comma-separated tokens.
// `options.padLeft` (default: `true`) pads a space left of each token, and
// `options.padRight` (default: `false`) pads a space to the right of each token.


function stringify(values, options) {
  var settings = options || {};
  var left = settings.padLeft === false ? empty : space;
  var right = settings.padRight ? space : empty; // Ensure the last empty entry is seen.

  if (values[values.length - 1] === empty) {
    values = values.concat(empty);
  }

  return values.join(right + comma + left).trim();
}

export default exports;
const _parse = exports.parse,
      _stringify = exports.stringify;
export { _parse as parse, _stringify as stringify };